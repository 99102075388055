import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import FullLogo from '../../assets/navigation/FullLogo.png';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import MobileNavItem from './MobileNavItem/MobileNavItem.jsx';
import SubNavItemsProduct from './MobileNavItem/SubNavItemsProduct.jsx';
import SubNavItemsActivity from './MobileNavItem/SubNavItemsActivity.jsx';
import Switch from '@material-ui/core/Switch';

const styles = {
  root: {
    width: '100vw',
    height: '54px',
    backgroundColor: '#5699cf',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  paper: {
    width: '100%',
    height: 'fit-content',
    backgroundColor: 'transparent'
  },
  img: {
    height: '5vh',
    transform: 'translateX(12px)',
  },
  icon: {
    height: '4vh'
  },
  drawer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#5196ce'
  },
  drawerhead: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '2%'
  },
  drawernavbar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  topnav: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    paddingTop: '3%',
    paddingBottom: '2%'
  },
  subnav: {
    display: 'flex',
    width: '100%',
    alignItems: 'center'
  },
  link: {
    color: '#ffffff',
    fontFamily: 'Noto Sans SC, sans-serif',
    fontWeight: '400'
  },
  button: {
    border: '2px solid transparent',
    borderRadius: '10px',
    minWidth: '56px',
    padding: '4px 6px',
    '&:hover': {
      border: '2px solid #ffffff'
    }
  },
  jump:{
    fontSize:12,
    position:'relative',
    color:'#fff',
    textAlign:'center',
    transform: 'translateX(60px)',
    '&::before':{
      position:'absolute',
      content:"''",
      top:0,
      bottom:0,
      left:0,
      right:0,
      backgroundColor:'transparent',
      zIndex:1,

    }
  },
  '@media screen and (max-width: 500px)': {
    icon: {
      height: '4vh'
    },
    paper: {},
    link: {
      fontSize: '10px'
    }
  },
  '@media screen and (max-width: 375px)': {
    button: {
      padding: '2px 3px'
    },
    link: {
      fontSize: '9px'
    }
  }
};

const MobileNav = props => {
  const { classes, navData } = props;
  var path = window.location.href;
  var origin = window.location.origin;
  const ckeckproduct = () => {
    var pageAdvUniv = origin + '/advUniv';
    return path.indexOf(pageAdvUniv) > -1;
  };
  const productpage =
    path === origin + '/talentPlan' ||
    path === origin + '/talentPlan' ||
    path === origin + '/advUniv' ||
    path === origin + '/firstAid' ||
    path === origin + '/fortunetIntern' ||
    // path === origin + '/globalIntern' ||
    // path === origin + '/flyForward' ||
    // path === origin + '/flyHome' ||
    ckeckproduct();
    const activitypage =path === origin + '/schoolHiring';
  // const activitypage =
  //   path === origin + '/schoolHiring' || path === origin + '/siliconValley';
  var aboutuspage = path === origin + '/aboutUs';
  var parternspage = path === origin + '/partners';
  var careerpage = path === origin + '/joinUs';

  const [showNav, toggleShowNav] = useState(false);
  const [dropdownOpens, setDropdownOpens] = useState(
    productpage ? [false, true] : activitypage ? [true, false] : [false, false]
  );
  const [navItems, getNavItems] = useState([]);

  useEffect(() => {
    const nav = [...navItems];
    const ifContains = target => {
      for (let ele of nav) {
        if (ele === target || ele.contains(target)) {
          return false;
        }
      }
      //if(activepage) { return false; }
      return true;
    };
    const listenClick = e => {
      if (ifContains(e.target)) {
        if (productpage) {
          setDropdownOpens([false, true]);
          return;
        }
        if (activitypage) {
          setDropdownOpens([true, false]);
          return;
        }
        setDropdownOpens([false, false]);
      }
    };
    window.addEventListener('click', listenClick);
    return () => {
      window.removeEventListener('click', listenClick);
    };
  });
  return (
    <div className={classes.root}>
      <div style={{ width: '0', left: '0', position: 'absolute' }}>
        <IconButton aria-label="NavMenu" onClick={() => toggleShowNav(true)}>
          <MenuIcon fontSize="large" style={{ color: 'white' }} />
        </IconButton>
      </div>
      <Drawer
        classes={{ paper: classes.paper }}
        id="outnav"
        anchor="left"
        open={showNav}
        onClose={() => {
          toggleShowNav(false);
          setDropdownOpens([false, false]);
        }}
      >
        <div tabIndex={0} role="button">
          <div className={classes.drawer}>
            <div className={classes.drawerhead}>
              <div style={{ width: '0', left: '0', position: 'absolute' }}>
                <IconButton
                  aria-label="NavMenu"
                  onClick={() => {
                    toggleShowNav(false);
                    setDropdownOpens([false, false]);
                  }}
                >
                  <CloseIcon fontSize="large" style={{ color: 'white' }} />
                </IconButton>
              </div>

              <img
                className={classes.icon}
                src={FullLogo}
                alt="FlagDream Logo"
              ></img>
            </div>
            <div className={classes.topnav}>
              <Button className={classes.button} size="medium">
                <a href="/" className={classes.link}>
                  {navData.mainNav.topLevel}
                </a>
              </Button>
              <MobileNavItem
                dropdownOpens={dropdownOpens}
                setDropdownOpens={setDropdownOpens}
                openStatus={dropdownOpens[1]}
                itemKey={1}
                topLevel={navData.productNav.topLevel}
                getNavItems={getNavItems}
                page={productpage}
              />
              {/* <MobileNavItem
                dropdownOpens={dropdownOpens}
                setDropdownOpens={setDropdownOpens}
                openStatus={dropdownOpens[0]}
                itemKey={0}
                topLevel={navData.activeNav.topLevel}
                getNavItems={getNavItems}
                page={activitypage}
              /> */}
              <Button className={classes.button} size="medium" style={activitypage ? { border: '2px solid #ffffff' } : {}}>
                <a href="/schoolHiring" className={classes.link}>
                  {navData.activeNav.topLevel}
                </a>
              </Button>

              {/* <Button
                className={classes.button}
                size="medium"
                style={parternspage ? { border: '2px solid #ffffff' } : {}}
              >
                <a href="/partners" className={classes.link}>
                  {navData.partnerNav.topLevel}
                </a>
              </Button> */}
              <Button
                className={classes.button}
                size="medium"
                style={aboutuspage ? { border: '2px solid #ffffff' } : {}}
              >
                <a href="/aboutUs" className={classes.link}>
                  {navData.aboutusNav.topLevel}{' '}
                </a>
              </Button>
              <Button
                className={classes.button}
                size="medium"
                style={careerpage ? { border: '2px solid #ffffff' } : {}}
              >
                <a href="/joinUs" className={classes.link}>
                  {navData.careerNav.topLevel}{' '}
                </a>
              </Button>
            </div>
            {dropdownOpens[1] ? <SubNavItemsProduct /> : <div></div>}
            {/* {dropdownOpens[0] ? <SubNavItemsActivity /> : <div></div>} */}
          </div>
        </div>
      </Drawer>
      <img className={classes.img} src={FullLogo} alt="FlagDream Logo"></img>
      {/* <a className={classes.jump} href="https://zh.flagdream.com/"><Switch  size="small"  /><br />
中文
      </a> */}
    </div>
  );
};

export default withStyles(styles)(MobileNav);
