import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import AppContainer from './layouts/AppContainer.jsx';
import appReducer from './global/reducers/index';
import thunkMiddleware from 'redux-thunk';
import './app.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.store = createStore(appReducer, applyMiddleware(thunkMiddleware));
  }
  render() {
    return (
      <Provider store={this.store}>
        <AppContainer />
      </Provider>
    );
  }
}

ReactDOM.render(<App />, document.getElementById('app'));
