import React from 'react';
import { withStyles } from '@material-ui/core';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import { API_URL } from '../../../global/API_URL.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Icon1 from '../../assets/homepage/footer/icon1.png';
import Icon2 from '../../assets/homepage/footer/icon2.png';
// import BarCode from '../../assets/homepage/footer/barcode.png';
import Icon3 from '../../assets/homepage/footer/Phone.png';
import OK from '../../assets/homepage/footer/OK.png';
import Close from '../../assets/homepage/footer/Close.png';
import Logo from '../../assets/homepage/footer/DrawerLogo.png';
// import { useWindowScroll } from '../../customHook.js';
import CryptoJS from 'crypto-js';
import './index.css';

const styles = {
  root: {
    //position: `${useWindowScroll() > 0 ? 'fixed' : 'relative' }`,
    width: '100vw',
    height: '34px',
    backgroundColor: '#505050',
    bottom: '0',
    display: 'flex',
    //flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '3px',
    paddingBottom: '3px',
    zIndex: '1'
  },
  paragraph: {
    color: '#ffffff',
    display: 'flex',
    fontSize: '16px',
    fontFamily: 'Noto Sans SC, sans-serif',
    fontWeight: '400'
  },
  p2: {
    color: '#82b0ed',
    fontSize: '16px',
    fontFamily: 'Noto Sans SC, sans-serif',
    fontWeight: '400'
  },
  form: {
    display: 'flex',
    //flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '30px'
  },
  textfield1: {
    backgroundColor: '#ffffff',
    width: '250px',
    height: '23px',
    justifyContent: 'center',
    alignItems: 'center',
    background: `url(${Icon1}) no-repeat;`,
    backgroundSize: '20px',
    paddingLeft: '45px',
    border: '1px solid black',
    borderRadius: '5px',
    outline: 'none',
    fontSize: '12px',
    backgroundPositionX: '6px',
    backgroundPositionY: 'center',
    marginLeft: '5px'
  },
  textfield2: {
    backgroundColor: '#ffffff',
    width: '250px',
    height: '23px',
    justifyContent: 'center',
    alignItems: 'center',
    background: `url(${Icon2}) no-repeat;`,
    backgroundSize: '10%',
    paddingLeft: '45px',
    border: '1px solid black',
    borderRadius: '5px',
    outline: 'none',
    fontSize: '12px',
    backgroundPositionX: '6px',
    backgroundPositionY: 'center',
    marginLeft: '5px'
  },
  textfield3: {
    backgroundColor: '#ffffff',
    width: '220px',
    height: '23px',
    justifyContent: 'center',
    alignItems: 'center',
    background: `url(${Icon3}) no-repeat;`,
    backgroundSize: 'contain',
    paddingLeft: '45px',
    border: '1px solid black',
    borderRadius: '5px',
    outline: 'none',
    fontSize: '12px',
    backgroundPositionX: '4px',
    backgroundPositionY: 'center',
    marginLeft: '5px'
  },
  submitButton: {
    width: '150px',
    height: '23px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: '#568bc7',
    color: '#fff',
    fontSize: '15px',
    fontFamily: 'Noto Sans SC, sans-serif',
    fontWeight: '400',
    marginLeft: '60px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  '@media screen and (max-width: 1350px)': {
    paragraph: {
      fontSize: '14px'
    },
    p2: {
      fontSize: '14px'
    },
    textfield1: {
      fontSize: '12px',
      width: '200px',
      marginLeft: '5px'
    },
    textfield2: {
      fontSize: '12px',
      width: '250px',
      marginLeft: '5px'
    },
    textfield3: {
      fontSize: '12px',
      width: '200px',
      marginLeft: '5px'
    }
  },
  '@media screen and (max-width: 1170px)': {
    paragraph: {
      fontSize: '12px'
    },
    p2: {
      fontSize: '12px'
    },
    textfield1: {
      fontSize: '10px',
      width: '150px',
      marginLeft: '0px'
    },
    textfield2: {
      fontSize: '10px',
      width: '240px',
      marginLeft: '0px'
    },
    textfield3: {
      fontSize: '10px',
      width: '180px',
      marginLeft: '0px'
    },
    submitButton: {
      marginLeft: '30px',
      fontSize: '12px'
    }
  },
  '@media screen and (max-width: 1050px)': {
    textfield1: {
      width: '135px',
      paddingLeft: '38px'
    },
    textfield2: {
      width: '220px',
      paddingLeft: '33px'
    },
    textfield3: {
      width: '170px',
      paddingLeft: '33px'
    },
    submitButton: {
      width: '100px',
      fontSize: '12px'
    }
  },
  '@media screen and (max-width: 980px)': {
    form: {
      paddingLeft: '10px'
    },
    submitButton: {
      marginLeft: '15px'
    }
  }
};

class ContactForm extends React.Component {
  // Initialize all contact form info as states
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      phone: '',
      wechat: '',
      eventId: '1',
      timestamp: '',
      salt: 'thisissalt',
      key: '',
      openDialog: false,
      windowScroll: 0
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.getScroll = this.getScroll.bind(this);
  }
  getScroll = () => {
    return window.scrollY;
  };
  componentDidMount() {
    this.scrollCallback = () => {
      this.setState({
        windowScroll: this.getScroll()
      });
    };
    window.addEventListener('scroll', this.scrollCallback);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollCallback);
  }

  // Update states as user types
  handleChange(event) {
    this.setState({
      [event.target.id]: event.target.value,
      timestamp: Date.now(),
      key: CryptoJS.MD5(this.state.salt + Date.now()).toString()
    });
  }

  // Axios to send to backend api
  handleSubmit(event) {
    event.preventDefault();
    const api = axios.create({
      withCredentials: true
    });
    api
      .post(API_URL.MAINPAGE_FORM, this.state, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        if (response.status === 201) {
          this.setState({
            openDialog: true
          });
        }
      })
      .then(res => {
        // console.log('Form sumbitted');
      })
      .catch(error => console.log(error));
    // this.setState({
    //   eventId: '1',
    //   salt: 'thisissalt',
    //   openDialog: true
    // });
  }

  // close dialog
  handleClose() {
    this.setState({
      email: '',
      wechat: '',
      phone: '',
      openDialog: false
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div
        className={classes.root}
        id="footer"
        style={{
          position: `${this.state.windowScroll > 0 ? 'fixed' : 'relative'}`
        }}
      >
        <Dialog
          open={this.state.openDialog}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ border: '4px solid #5195cd' }}
        >
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              <img style={{ width: '50%' }} src={Close} alt="close"></img>
            </Button>
          </DialogActions>
          <DialogContent
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '0px'
            }}
          >
            <img
              style={{ width: '60%', position: 'relative', top: '-25px' }}
              src={Logo}
              alt="Logo"
            ></img>
            <DialogContentText id="alert-dialog-thank">
              感谢您提交的信息
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
              稍后FLAGDream的顾问团队会跟您联系
            </DialogContentText>
          </DialogContent>
          <DialogActions
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: '20%',
              backgroundColor: '#5195cd'
            }}
          >
            <Button
              onClick={this.handleClose}
              style={{ width: '100%', backgroundColor: '#5195cd' }}
            >
              <img style={{ width: '20%' }} src={OK} alt="ok"></img>
            </Button>
          </DialogActions>
        </Dialog>
        <p className={classes.paragraph}>免费获取价值</p>
        <p className={classes.p2}>$699</p>
        <p className={classes.paragraph}>的求职资料包+职业规划</p>
        <form
          className={classes.form}
          autoComplete="off"
          onSubmit={this.handleSubmit}
        >
          <TextField
            type="text"
            id="wechat"
            //label="Wechat/Tel"
            placeholder="请输入微信"
            className={classes.textfield1}
            value={this.state.wechat}
            onChange={this.handleChange}
          ></TextField>
          {/* <p style = {{color: '#ffffff', textAlign: 'center', fontSize: '18px', marginLeft: '5px', marginRight: '5px'}}>或</p> */}
          <TextField
            required
            type="email"
            id="email"
            //label="Email"
            placeholder="请输入您的邮箱（必填）"
            className={classes.textfield2}
            value={this.state.email}
            onChange={this.handleChange}
          ></TextField>
          <TextField
            type="text"
            id="phone"
            //label="Email"
            placeholder="请输入您的电话"
            className={classes.textfield3}
            value={this.state.phone}
            onChange={this.handleChange}
          ></TextField>
          <button type="submit" className={classes.submitButton}>
            立刻领取
          </button>
        </form>
      </div>
    );
  }
}

// const Contact = props => {
//   const { classes } = props;
//   const windowScroll = useWindowScroll();
//   const handleSubmit = event => {
//     event.preventDefault();
//     // axios.post(API_V1_URL.CONTACT_FORM, this.state).
//     // then(res => {console.log('Form sumbitted');});
//     // this.setState({
//     //   name: "",
//     //   email: "",
//     //   wechatID: "",
//     //   content: "",
//     //   openDialog: true
//     // });
//   };

//   return (
//     <div
//       className={classes.root}
//       id="footer"
//       style={{ position: `${windowScroll > 0 ? 'fixed' : 'relative'}` }}
//     >
//       {/* <div
//         style={{
//           width: '130px',
//           height: '130px',
//           position: 'relative',
//           left: '40%',
//           top: '-200px'
//         }}
//       >
//         <img
//           style={{ width: '130px', height: '130px' }}
//           src={BarCode}
//           alt="suspension"
//         ></img>
//         <div
//           style={{
//             display: 'flex',
//             justifyContent: 'center',
//             flexDirection: 'column',
//             alignItems: 'center'
//           }}
//         >
//           <p style={{ fontSize: '14px' }}>免费获取</p>
//           <p style={{ fontSize: '18px', color: '#82b0ed' }}>$699</p>
//           <p style={{ fontSize: '14px' }}>的求职资料包</p>
//         </div>
//       </div> */}

//       <p className = {classes.paragraph}>免费获取价值</p>
//       <p className = {classes.p2}>$699</p>
//       <p className = {classes.paragraph}>的求职资料包+职业规划</p>
//       <form className = {classes.form} autoComplete="off" onSubmit={handleSubmit}>
//           <input
//               type = 'text'
//               id="wechat"
//               //label="Wechat/Tel"
//               placeholder = "请输入微信"
//               contentEditable="true"
//               className = {classes.textfield1}
//               >
//           </input>
//           {/* <p style = {{color: '#ffffff', textAlign: 'center', fontSize: '18px', marginLeft: '5px', marginRight: '5px'}}>或</p> */}
//           <input
//               required type = 'email'
//               id="email"
//               //label="Email"
//               placeholder = "请输入您的邮箱（必填）"
//               contentEditable="true"
//               className = {classes.textfield2}
//               >
//           </input>
//           <input
//               type = 'text'
//               id="telephone"
//               //label="Email"
//               placeholder = "请输入您的电话"
//               contentEditable="true"
//               className = {classes.textfield2}
//               >
//           </input>
//           <button type = 'submit' className = {classes.submitButton}>立刻领取</button>
//       </form>
//     </div>
//   );
// };

export default withStyles(styles)(ContactForm);
