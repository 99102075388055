const API_URL = {
  MAINPAGE_FORM: 'https://u-offer-api-prod.hitalentech.com/flagdream/api/guest-register',
  LOGIN: {
    LINKEDIN:
      'https://www.linkedin.com/uas/oauth2/authorization?response_type=code&client_id=86goce5yyba3rn&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fauth%2Flinkedin/callback&state=987654321&scope=r_liteprofile%20r_emailaddress',
    LINKEDINAUTH:
      'https://u-offer-api-prod.hitalentech.com/flagdream/api/v1/users/login/social/auth',
    GOOGLE: '/login/auth/google',
    GITHUB: '/login/auth/github'
  },
  SWIPER: {
    SWIPER: 'https://api-prod.flagdream.com/flagdream/api/v1/swipers'
  }
};

export { API_URL };
