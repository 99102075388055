import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
// import SubNavItems from './SubNavItemsProduct.jsx';

const styles = {
  root: {
    position: 'relative',
    border: '2px solid transparent',
    borderRadius: '10px',
    minWidth: '56px',
    padding: '4px 6px',
    '&:hover': {
      border: '2px solid #ffffff'
    }
  },
  link: {
    color: '#ffffff',
    fontFamily: 'Noto Sans SC, sans-serif',
    fontWeight: '400'
  },
  '@media screen and (max-width: 500px)': {
    link: {
      fontSize: '10px'
    }
  },
  '@media screen and (max-width: 375px)': {
    root: {
      padding: '2px 3px'
    },
    link: {
      fontSize: '9px'
    }
  }
};

const MobileNavItem = props => {
  const {
    dropdownOpens,
    itemKey,
    openStatus,
    setDropdownOpens,
    getNavItems,
    classes,
    page
  } = props;
  // var path = window.location.href;
  // var origin = window.location.origin;
  // var productpage =
  //   path === origin + '/talentPlan' ||
  //   path === origin + '/talentPlan' ||
  //   path === origin + '/advUniv' ||
  //   path === origin + '/firstAid' ||
  //   path === origin + '/fortunetIntern' ||
  //   path === origin + '/globalIntern' ||
  //   path === origin + '/flyForward' ||
  //   path === origin + '/flyHome';
  const [showDropdown, toggleShowDropdown] = useState(false);
  useEffect(() => {
    toggleShowDropdown(openStatus);
  }, [openStatus]);
  useEffect(() => {
    if (itemKey === 1) {
      getNavItems(document.getElementsByClassName('sideNavItem'));
    }
  }, []);
  return (
    <Button
      size="medium"
      className={`${classes.root} sideNavItem`}
      style={page ? { border: '2px solid #ffffff' } : {}}
      onClick={() => {
        toggleShowDropdown(!showDropdown);
        const opens = dropdownOpens.map((ele, index) => {
          if (index === itemKey) {
            return true;
          }
          return false;
        });
        setDropdownOpens(opens);
      }}
    >
      <div className={classes.link}>{props.topLevel}</div>
    </Button>
  );
};

export default withStyles(styles)(MobileNavItem);
