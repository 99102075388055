import React, { useEffect } from 'react';
import Nav from './Nav.jsx';
import { withStyles } from '@material-ui/core/styles';
import { NAV_DATA } from '../../data.jsx';
import './Navigation.css';

const styles = {
  root: {
    display: 'flex',
    // justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    //height: '79px',
    position: 'fixed',
    top: 0,
    boxShadow: '0 2px 4px 0 rgba(32, 33, 36, 0.28)',
    backgroundColor: '#ffffff',
    zIndex: '10'
  }
  // '@media screen and (max-width: 720px)': {
  //   root: {
  //     width: '720px'
  //   }
  // }
};

const Navbar = props => {
  const { windowScroll, classes } = props;
  // useEffect(() => {
  //     const nav = document.getElementById('nav');

  // })
  return (
    <nav id="nav" className={classes.root}>
      <Nav navData={NAV_DATA} />
    </nav>
  );
};

export default withStyles(styles)(Navbar);
