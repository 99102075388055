import yisuImg from './assets/aboutUS/man.png';
import alexImg from './assets/aboutUS/boy.png';
import audreyImg from './assets/aboutUS/girl.png';
import timImg from './assets/aboutUS/boy (1).png';
import desImg from './assets/aboutUS/man (1).png';
import amandaImg from './assets/aboutUS/girl (3).png';
import ericImg from './assets/aboutUS/guy.png';
import oliviaImg from './assets/aboutUS/girl (2).png';
import rickImg from './assets/aboutUS/young.png';

const NAV_DATA = {
  mainNav: {
    topLevel: 'Home'
  },
  productNav: {
    topLevel: 'Product',
    secondLevel: [
      {
        name: 'Talent Plan',
        url: ''
      },
      {
        name: 'Advanced University',
        url: ''
      },
      {
        name: 'Job Search First Aid Kit',
        url: ''
      },
      {
        name: 'InternshipsPrograms',
        url: ''
      },
      // {
      //   name: '国际实习实训计划',
      //   url: ''
      // },
      // {
      //   name: '归燕计划',
      //   url: ''
      // },
      // {
      //   name: '飞燕计划',
      //   url: ''
      // }
    ]
  },
  activeNav: {
    topLevel: 'Activities',
    secondLevel: []
  },
  // partnerNav: {
  //   topLevel: '合作伙伴'
  // },
  aboutusNav: {
    topLevel: 'About Us'
  },
  careerNav: {
    topLevel: 'Join Us'
  }
};

const HOMEPAGE_DATA = {
  INFO_DATA: {
    introduction:
      `FLAGDream is an education technology corporation founded by an elite team from top tech companies in Silicon Valley. Our mission is to help students join the world's leading companies, relying on our strong resources, networks, and mentor teams throughout Silicon Valley and Wall Street. FLAGDream provides students with career development, consulting, and vocational skill training services. We cover industry trends such in computer science, AI, finance, management consulting, business analysis, FMCG/CPG, culture/creative industries, and more. As a result, we send thousands of trainees to Silicon Valley and top companies in various industries around the world every year.`,
    advantage: `FLAGDream is an affiliate of one of the top human resource consultancies, IntelliPro Group, based in North America and founded in 2009. IntelliPro Group has provided career advice and opportunities to 100,000+ talents to help them develop in all aspects of their career. IntelliPro has become a top partner for technology companies in the United States and the Asia-Pacific region. With high-tech methods leveraging AI and big data, IntelliPro Group has established a large-scale talent database of over 160 million, which can quickly meet the needs of business for elite talent. IntelliPro Group provides comprehensive talent solutions for corporate customers including high-end recruitment, management consulting, technical consulting, compensation, HR management, and outsourcing. They are committed to providing high-quality service to partners to empower their business.`,
    company:`FLAGDream maintains a long-term cooperative relationship with major enterprises. We continue to promote trainees' employment and internships in those enterprises, including: 
      Google, Apple, Amazon, LinkedIn, Airbnb, Facebook, Pinterest, Adobe, Snapchat, JPMorgan, Samsung, Waymo, Cruise, Nuro, Uber, Alibaba, Tencent, Baidu, Bytedance, JD.com, Ant Financial Services Group, Xiaomi, OPPO, Qihoo 360, Jingchi Technology, DiDi, Verizon, Nest, TuSimple, Dell, and more!`,
    teacher:
      'FLAGDream导师团队由来自各行业顶尖公司的资深导师组成，如科技领域涵盖Google, Apple, Facebook, Amazon, LinkedIn, Airbnb 等；金融领域包括Goldman Sachs, Morgan Stanley, Credit Suisse, JP Morgan Chase, Deutsche Bank, BNP Paribas, Citibank, Barclays Capital, Bank of America Merrill Lynch等；咨询公司包括McKinsey, Bain, Boston Consulting, Oliver Wyman, L.E.K. Consulting, A.T. Kearney, Ernst & Young, PricewaterhouseCoopers, Deloitte, KPMG等。每一位导师均有精深的专业知识和丰富的培训经验。',
    course:
      'FLAGDream提供的专业知识培训、职业技能培训、背景提升项目、职业发展咨询等课程服务，通过高科技手段及丰富的课程形式，为学员提供定制化的教学服务与个性化的学习体验。'
  },
  HEADER_DATA: {
    slider2:
      'FLAGDream is IntelliPro Group’s exclusive strategic partner in campus recruiting. In 2019, we held general graduate & doctoral conferences at 32 US TOP 50 (US) universities, and 25 North American corporate recruitment events on behalf of exclusive partners.',
    slider3:
      'Talk with FLAG is an interview event for FLAGDream in Silicon Valley. FLAGDream will invite senior corporate managers, directors, and other tech industry leaders to discuss hot topics in technology, industry trends, and share insights on career development together.'
  },
  COURSE_DATA: {
    course1:
      '（Chinese proverb）Traveling thousands of miles is not as good as expert guidance. Senior industry seniors help you plan your career development and find the most suitable development direction.',
    course2:
      `Breakthrough in core skills in popular positions. Mentors will help you master the core knowledge and skills required for the position, effectively prepare for the technical interview, and help you in your job search and work.
      `,
    course3:
      `Fully prepare for written tests and interviews in popular positions. Senior mentors help you quickly understand assessment criteria, characteristics of different industries and positions, quickly form a plan, and help you stand out during the application process.
      `,
    course4:
      `Over a decade of relationships with high-end headhunters, an extensive network of corporate cooperation, and a scientific and efficient job matching system will help you to get a dream offer.
      `,
    course5:
      `If you are conflicted on how to choose the best offer when there are multiple options? If you are concerned about how to integrate into a team as a new colleague, don‘t worry. Our mentor will help you analyze and be sure you are well prepared.
      `,
    coursetitle1: 'Career planning',
    coursetitle2: 'Vocational skill training',
    coursetitle3: 'Job search skills training',
    coursetitle4: 'Enrich referral opportunities',
    coursetitle5: 'Offer selection guidance and onboarding preparation'
  },
  TEACHERSERVICE_DATA: {
    content:`FLAGDream ensures that students get the most efficient and professional training. Services cover all features of the 7-level mentor service system, and perfectly combine position matching resources to help trainees achieve their dream of entering a world-class company. The 7 Levels of mentorship include:`
  },
  COMMENT_DATA: {
    comment1: {
      comment:
        '"There’s a high bar for quality education. Completing the course was such a worthwhile investment! FLAGDream taught me a lot and saved me when I encountered difficulties and was confused when looking for a job."',
      name: 'Y. Wang',
      position: 'Software Engineer',
      company: '@Google'
    },
    comment2: {
      comment:
        '"FLAGDream’s algorithm course made me feel more confident during the interview. I’m grateful to the instructors for their support & companionship throughout my job search."',
      name: 'X. Pu',
      position: 'Software Engineer',
      company: '@Facebook'
    },
    comment3: {
      comment:
        '"The content of the course was very helpful, and I learned a lot. I am also very grateful to my teacher for helping to refer me, so that I successfully found a job."',
      name: 'Z. Peng',
      position: 'Software Engineer',
      company: '@Amazon'
    },
    comment4: {
      comment:
          `"After working for a year, I wanted to change jobs and learn new technology, but I hadn't been able to find good enough projects. FLAGDream gave me the opportunity to fill in the technical gaps in my experience before I changed jobs."`,
      name: 'G. Ling',
      position: 'Software Engineer',
      company: '@LinkedIn'
    },
    comment5: {
      comment:
        `"The content of the data science course is very rich, and the Case Interview section helped me a lot during the interview. FLAGDream's teachers also gave me plenty of suggestions during my job hunt!"`,
      name: 'B. Wang',
      position: 'Data Analyst',
      company: '@Airbnb'
    },
    comment6: {
      comment:
        '"The instruction provided by the teacher is super useful! Thanks to the teacher’s guidance, I got the offer."',
      name: 'T. Yu',
      position: 'Software Engineer',
      company: '@Amazon'
    },
    comment7: {
      comment:
        '"I not only learned skills in class, but also found my job through a FLAGDream’s career fair. Thank you very much for your help!"',
      name: 'X. Chen',
      position: 'Software Engineer',
      company: '@Samsung'
    },
    comment8: {
      comment:
        '"After class, my instructor referred and guided me to receive my first DS offer! I hope FLAGDream will keep expanding its offerings. I’ll be back to learn more for the next step in my career."',
      name: 'Q. Ye',
      position: 'Data Scientist',
      company: '@WalmartLabs'
    }
  }
};

const ABOUTUS_DATA = {
  yisu: {
    name: 'Yisu',
    des:
      'UIUC数学系硕士，硅谷持续创业者。北美华人最大人才咨询管理公司IntelliPro Group Inc.创始人兼CEO；SVLC (Silicon VAlley Leadership Community) 的发起人之一；也是FLAGDream，SVInsights(硅谷密探)的投资人。2000年来到硅谷，曾先后在Oracle、IBM、Cisco等公司从事技术开发、咨询、项目管理等工作。',
    decImg: yisuImg
  },
  alex: {
    name: 'Alex',
    des:
      '15年毕业于美国新常春藤大学后来到硅谷工作，目前在一家新晋IPO公司做高级算法工程师，之前分别在美国一线大厂做机器学习工程师以及中国一线大厂美国分部做全栈软件工程师。做过的项目包括全栈开发，数据分析管道的搭建，以及基础核心算法的优化。作为候选人面试过50+公司，作为面试官面试过100+候选人。',
    decImg: alexImg
  },
  audrey: {
    name: 'Audrey',
    des:
      '资深硅谷产品经理，曾就职于苹果，雅虎，Comcast. 北大药学院毕业，赴美留学后任职软件工程师，成功转型做产品经理。曾负责苹果iTunes Payments big data platform and analytics product，雅虎最大用户数据平台产品 支持雅虎广告业务和搜索引擎。擅长技术产品开发，管理，agile/scrum methodology 应用。',
    decImg: audreyImg
  },
  tim: {
    name: 'Tim',
    des:
      'Tim导师有着理工科和商科的复合型背景。毕业后加入顶级投行，从事衍生品相关的工作。Tim导师专注于辅导 quant / data scientist / trading 相关领域，辅导达百人次，学员好评率100%',
    decImg: timImg
  },
  des: {
    name: 'Des',
    des:
      'Des 导师本科UBC生物化学，后看到行业的前景，转成CS。毕业后加入在线财务软件的创业公司一年后加入在亚马逊。凭借出色的技术能力和团队领导力，快速晋升为SDE III。Des曾作为主考官之一，参与过五百场以上的面试。同时，作为SDE I/II/III的晋升评审和内部的职业导师，带领、培养了大批优秀后辈。',
    decImg: desImg
  },
  amanda: {
    name: 'Amanda',
    des:
      'Amanda毕业于沃顿商学院，目前就职于顶尖投行公司并已有9年的工作经历。作为资深面试官，深知投行求职导向，技术导向。顺利帮助多位同学拿到投行offer。',
    decImg: amandaImg
  },
  eric: {
    name: 'Eric',
    des:
      '毕业于top2理工科专业，同时拥有经济学双学位，准保荐代表人，具有丰富的工科转行金融领域求职，同时积累了保代、CPA考试得复习备考经验。从业以来主要从事A股IPO，再融资及并购相关业务，工作经验丰富。',
    decImg: ericImg
  },
  olivia: {
    name: 'Olivia',
    des:
      '谷歌的高级产品经理。她负责的AI驱动的广告产品每年为谷歌带来数亿美元的收入。在加入谷歌之前，Olivia先后创建了Bridge，为中老年人学习新兴科技而服务的教育公司；和Olivia AI，用人工智能技术为用户做理财服务的互联网金融公司。',
    decImg: oliviaImg
  },
  rick: {
    name: 'Rick',
    des:
      '拥有8年工业界经验，专注云计算。曾在微软合亚马逊AWS任软件工程师。华盛顿大学博塞尔分校Computing & Software Systems (CSS)的客座教授。Rick导师会在扎实理解理论知识点的基础上，强调实际问题的分析求解和输出高质量代码。授课中引入工业界的实战经验和常用工具。',
    decImg: rickImg
  }
};

export { NAV_DATA, HOMEPAGE_DATA, ABOUTUS_DATA };
