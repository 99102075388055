import React, { useState, useEffect } from 'react';
// import Logo from './Logo.jsx';
import { NavLink } from 'react-router-dom';
import logoImg from '../../assets/navigation/FullLogo.png';
import { withStyles } from '@material-ui/core';
// import Theme from '../../assets/navigation/NavBar.jpeg';
import Button from '@material-ui/core/Button';
// import BrowserNavItem from './BrowserNavItem.jsx';
import Switch from '@material-ui/core/Switch';

const styles = {
  root: {
    width: '100%',
    position: 'relative',
    height: '54px',
    // background: `url('${Theme}') no-repeat`,
    // backgroundSize: '100% 100%',
    display: 'flex',
    alignItems: 'center',
    backgroundColor:'#318ccd'
  },
  logo: {
    display: 'flex',
    width: '35%',
    height: '100%',
    backgroundColor: '#318ccd',
    '&::after': {
      content: '""',
      width: '0',
      height: '0',
      position: 'absolute',
      left: '35%',
      display: 'inline-block',
      borderTop: '27px solid transparent',
      borderLeft: '18px solid #318ccd',
      borderBottom: '27px solid transparent'
    }
  },
  title: {
    display: 'flex',
    paddingLeft: '60%',
    alignItems: 'center'
  },
  img: {
    height: '80%',
    width: '78%'
  },
  title1: {
    fontWeight: '800',
    color: '#ffffff'
  },
  title2: {
    fontWeight: '400',
    color: '#ffffff'
  },

  nav: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '50%',
    height: '100%',
    alignItems: 'center',
    color: '#ffffff',
    backgroundImage:
      'linear-gradient(90deg, rgb(126, 179, 219) 10%, rgb(49, 140, 205) 90%)',
    paddingRight: '10%',
    '& ul > *': {
      cursor: 'pointer'
    }
  },
  button: {
    padding: '0px',
    border: '2px solid transparent',
    borderRadius: '10px',
    '&:hover': {
      border: '2px solid #ffffff'
    }
  },
  link: {
    fontSize: '16.5px',
    color: '#ffffff',
    paddingLeft: '3px',
    paddingRight: '3px',
    fontFamily: 'Noto Sans SC, sans-serif',
    fontWeight: '400',
    textTransform:'Capitalize'

  },
  // text: {
  //   paddingTop: '0px',
  //   paddingBottom: '0px',
  //   paddingRight: '35px',
  //   paddingLeft: '0px'
  // },
  jump:{
    position:'relative',
    color:'#fff',
    '&::before':{
      position:'absolute',
      content:"''",
      top:0,
      bottom:0,
      left:0,
      right:0,
      backgroundColor:'transparent',
      zIndex:1,

    }
  },
  '@media screen and (max-width: 1700px)': {
    title: {
      paddingLeft: '50%'
    }
  },
  '@media screen and (max-width: 1500px)': {
    title: {
      paddingLeft: '45%'
    }
  },
  '@media screen and (max-width: 1350px)': {
    title: {
      paddingLeft: '40%'
    }
  },
  '@media screen and (max-width: 1200px)': {
    title: {
      paddingLeft: '35%'
    },
    link: {
      fontSize: '16px',
      paddingLeft: '3px',
      paddingRight: '3px'
    }
  },
  '@media screen and (max-width: 1000px)': {
    title: {
      paddingLeft: '20%'
    }
  },
  '@media screen and (max-width: 950px)': {
    title: {
      paddingLeft: '15%'
    },
    nav: {
      paddingRight: '4%'
    },
    img: {
      height: '72%'
    },
    link: {
      fontSize: '14px'
    }
  },
  '@media screen and (max-width: 900px)': {
    title: {
      paddingLeft: '10%'
    }
  },
  '@media screen and (max-width: 850px)': {
    title: {
      paddingLeft: '8%'
    },
    nav: {
      paddingRight: '4%'
    }
  }
};

const BrowserNav = props => {
  const { classes, navData } = props;
  // const [dropdownOpens, setDropdownOpens] = useState([false, false]);

  // useEffect(() => {
  //   //const nav = document.getElementsByClassName('topNavItem');
  //   // const ifContains = (target) => {
  //   //     for(let ele of nav)
  //   //     {
  //   //         if(ele === target || ele.contains(target))
  //   //         {
  //   //             console.log(ele);
  //   //             console.log('not leave');
  //   //             return false;
  //   //         }
  //   //     }
  //   //     console.log('leave');
  //   //     return true;
  //   // }
  //   const listenClick = e => {
  //     // console.log('listen');
  //     // if(ifContains(e.target))
  //     // {
  //     //     console.log('click');
  //     setDropdownOpens([false, false]);
  //     //}
  //   };
  //   var Nav0 = document.getElementById('Nav0');
  //   var Nav1 = document.getElementById('Nav1');
  //   Nav0.addEventListener('mouseleave', listenClick);
  //   Nav1.addEventListener('mouseleave', listenClick);
  //   return () => {
  //     Nav0.removeEventListener('mouseleave', listenClick);
  //     Nav1.removeEventListener('mouseleave', listenClick);
  //   };
  // });
  var path = window.location.href;
  var origin = window.location.origin;
  const ckeckproduct = () => {
    var pageAdvUniv = origin + '/advUniv';
    return path.indexOf(pageAdvUniv) > -1;
  };
  var productpage =
    path === origin + '/talentPlan' ||
    path === origin + '/talentPlan' ||
    path === origin + '/advUniv' ||
    path === origin + '/firstAid' ||
    path === origin + '/fortunetIntern' ||
    // path === origin + '/globalIntern' ||
    // path === origin + '/flyForward' ||
    // path === origin + '/flyHome' ||
    ckeckproduct();
  var activitypage =path === origin + '/schoolHiring' || path === origin + '/siliconValley';
  var aboutuspage = path === origin + '/aboutUs';
  var partnerspage = path === origin + '/partners';
  var careerpage = path === origin + '/joinUs';

  const [productPage, matchUrl] = useState(false);
  const [activityPage, matchUrls] = useState(false);
  const [aboutusPage, matchUrllast] = useState(false);
  const [partnersPage, matchUrlpartner] = useState(false);
  const [careerPage, matchUrlcareer] = useState(false);

  useEffect(() => {
    if (productpage) {
      matchUrl(true);
    }
    if (activitypage) {
      matchUrls(true);
    }
    if (aboutuspage) {
      matchUrllast(true);
    }
    if (partnerspage) {
      matchUrlpartner(true);
    }
    if (careerpage) {
      matchUrlcareer(true);
    }
  });
  return (
    <div className={classes.root} id="navselect">
      <div className={classes.logo}>
        <div className={classes.title}>
        <a href="/" className={classes.link}>
          <img
            className={classes.img}
            src={logoImg}
            alt="flag dream logo"
          ></img>
          </a>
        </div>
      </div>
      <ul className={classes.nav}>
        <div className={classes.text}>
          <Button className={classes.button} size="medium">
            <a href="/" className={classes.link}>
              {navData.mainNav.topLevel}
            </a>
          </Button>
        </div>
        <div className={classes.text}>
          <Button
            className={classes.button}
            size="medium"
            style={productPage ? { border: '2px solid #ffffff' } : {}}
          >
            <a href="/talentPlan" className={classes.link}>
              {navData.productNav.topLevel}
            </a>
          </Button>
        </div>
        <div className={classes.text}>
          <Button
            className={classes.button}
            size="medium"
            style={activityPage ? { border: '2px solid #ffffff' } : {}}
          >
            <a href="/schoolHiring" className={classes.link}>
              {navData.activeNav.topLevel}
            </a>
          </Button>
        </div>
        {/* <div className={classes.text}>
          <BrowserNavItem
            topLevel={navData.productNav.topLevel}
            dropdownOpens={dropdownOpens}
            setDropdownOpens={setDropdownOpens}
            openStatus={dropdownOpens[0]}
            itemKey={0}
            secondLevel={navData.productNav.secondLevel}
          />
        </div>
        <div className={classes.text}>
          <BrowserNavItem
            topLevel={navData.activeNav.topLevel}
            dropdownOpens={dropdownOpens}
            setDropdownOpens={setDropdownOpens}
            openStatus={dropdownOpens[1]}
            itemKey={1}
            secondLevel={navData.activeNav.secondLevel}
          />
        </div> */}
        {/* <div className={classes.text}>
          <Button
            className={classes.button}
            size="medium"
            style={partnersPage ? { border: '2px solid #ffffff' } : {}}
          >
            <a href="/partners" className={classes.link}>
              {navData.partnerNav.topLevel}
            </a>
          </Button>
        </div> */}
        <div className={classes.text}>
          <Button
            className={classes.button}
            style={aboutusPage ? { border: '2px solid #ffffff' } : {}}
          >
            <a href="/aboutUs" className={classes.link}>
              {navData.aboutusNav.topLevel}
            </a>
          </Button>
        </div>

        <div className={classes.text}>
          <Button
            className={classes.button}
            style={careerPage ? { border: '2px solid #ffffff' } : {}}
          >
            <a href="/joinUs" className={classes.link}>
              {navData.careerNav.topLevel}
            </a>
          </Button>
        </div>
      </ul>
      {/* <a className={classes.jump} href="https://zh.flagdream.com/">
        <Switch/>中文</a> */}
    </div>
  );
};

export default withStyles(styles)(BrowserNav);
