import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { HOMEPAGE_DATA, ABOUTUS_DATA } from '../market/data.jsx';
import NavBar from '../market/components/Navigation/index';
import Footer from '../market/components/Footer/index';
// import Homepage from '../market/components/HomePage/index.jsx';
// import AdvUniv from '../market/components/AdvUniv/index.jsx';
// import TalentPlan from '../market/components/TalentPlan/index.jsx';
// import InternationalIntern from '../market/components/InternationalIntern/index.jsx';

// import FortunetIntern from '../market/components/FortunetIntern/index.jsx';

// import FlyForward from '../market/components/FlyForward/index.jsx';
// import FlyHome from '../market/components/FlyHome/index.jsx';

// import JobMedical from '../market/components/JobMedical/index.jsx';
// import SchoolHiring from '../market/components/SchoolHiring/index.jsx';
// import SiliconValley from '../market/components/SiliconValley/index.jsx';
// import AboutUs from '../market/components/AboutUs/index.jsx';
// import Partners from '../market/components/Partners/index';
// import Career from '../market/components/Career/index';
// import JobDetail from '../market/components/Career/Introduction/JobDetail';
import LoginPage from '../views/LoginPage';
import DashBoard from '../views/DashBoard';
import RedirectPage from '../views/RedirectPage';

const AsyncHomepage = lazy(() =>
  import('../market/components/HomePage/index.jsx')
);
const AsyncTalentPlan = lazy(() =>
  import('../market/components/TalentPlan/index.jsx')
);
const AsyncInternationalIntern = lazy(() =>
  import('../market/components/InternationalIntern/index.jsx')
);
const AsyncFortunetIntern = lazy(() =>
  import('../market/components/FortunetIntern/index.jsx')
);
const AsyncFlyForward = lazy(() =>
  import('../market/components/FlyForward/index.jsx')
);
const AsyncFlyHome = lazy(() =>
  import('../market/components/FlyHome/index.jsx')
);
const AsyncSchoolHiring = lazy(() =>
  import('../market/components/SchoolHiring/index.jsx')
);
const AsyncSiliconValley = lazy(() =>
  import('../market/components/SiliconValley/index.jsx')
);
const AsyncJobMedical = lazy(() =>
  import('../market/components/JobMedical/index.jsx')
);
const AsyncJobDetail = lazy(() =>
  import('../market/components/Career/Introduction/JobDetail')
);
const AsyncPartners = lazy(() => import('../market/components/Partners/index'));
const AsyncAboutUs = lazy(() =>
  import('../market/components/AboutUs/index.jsx')
);
const AsyncCareer = lazy(() => import('../market/components/Career/index'));
const AsyncAdvUniv = lazy(() =>
  import('../market/components/AdvUniv/index.jsx')
);

class AppContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  // Talent Plan talentPlan,

  // Advanced University advUniv,
  // Job Search First Aid Kit firstAid,
  // InternshipsPrograms fortunetIntern.
  // 国际实习实训计划 globalIntern,
  // 飞燕计划 flyForward,
  // 归燕计划 flyHome
  render() {
    return (
      <BrowserRouter>
        <NavBar />
        <Suspense fallback={<div />}>
          <Switch>
            <Route
              exact
              path="/"
              // component = {AsyncHomepage}
              render={props => <AsyncHomepage homepageData={HOMEPAGE_DATA} />}
            />
            <Route
              path="/advUniv"
              component={AsyncAdvUniv}
              // render={props => <AsyncAdvUniv homepageData={HOMEPAGE_DATA} />}
            />
            {/* <Route 
                exact path = "/advUniv/:courseId"
                component={AsyncCoursePage}
              /> */}
            <Route
              exact
              path="/talentPlan"
              render={props => <AsyncTalentPlan />}
              // component = {AsyncTalentPlan}
            />
            <Route
              exact
              path="/globalIntern"
              // component = {AsyncInternationalIntern}
              render={props => <AsyncInternationalIntern />}
            />

            <Route
              exact
              path="/fortunetIntern"
              // component = {AsyncFortunetIntern}
              render={props => <AsyncFortunetIntern />}
            />
            <Route
              exact
              path="/flyForward"
              // component = {AsyncFlyForward}
              render={props => <AsyncFlyForward />}
            />
            <Route
              exact
              path="/flyHome"
              // component = {AsyncFlyHome}
              render={props => <AsyncFlyHome />}
            />
            <Route
              exact
              path="/firstAid"
              render={props => <AsyncJobMedical homepageData={HOMEPAGE_DATA} />}
            />
            <Route
              exact
              path="/schoolHiring"
              // component = {AsyncSchoolHiring}
              render={props => <AsyncSchoolHiring />}
            />
            <Route
              exact
              path="/siliconValley"
              // component = {AsyncSiliconValley}
              render={props => <AsyncSiliconValley />}
            />
            <Route
              exact
              path="/aboutUs"
              render={props => (
                <AsyncAboutUs Data={HOMEPAGE_DATA} TeacherData={ABOUTUS_DATA} homepageData={HOMEPAGE_DATA} />
              )}
            />
            <Route
              exact
              path="/partners"
              render={props => <AsyncPartners homepageData={HOMEPAGE_DATA} />}
            />
            <Route path="/jobDetail/:id" component={AsyncJobDetail} />
            <Route exact path="/joinUs" render={props => <AsyncCareer />} />
            <Route exact path="/login" component={LoginPage} />
            <Route path="/auth/linkedin/callback" component={RedirectPage} />
            <Route path="/dashboard" component={DashBoard} />
          </Switch>
        </Suspense>
        <Footer />
      </BrowserRouter>
    );
  }
}

export default AppContainer;
