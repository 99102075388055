import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    // width: '10vw',
    height: '16vw',
    width: '16vw',
    alignItems: 'center',
    justifyContent: 'center',
    margin:'2% 8px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  des: {
    fontSize: '10px',
    color: '#000000',
    lineHeight: '1.2',
    textAlign:'center'
  },
  des2: {
    fontSize: '10px',
    color: '#000000',
    lineHeight: '1.21'
  },
  pic: {
    height: '8vw'
  },
  '@media screen and (max-width: 500px)': {
    pic: {
      height: '6vw'
    }
  }
};

const Title = props => {
  const { classes, pic, des, des2, path } = props;
  const [thisPage, matchUrl] = useState(false);
  useEffect(() => {
    var url = window.location.href;
    if (
      url === window.location.origin + path ||
      url === window.location.origin + path.substring(1)
    )
      matchUrl(true);
  });
  return (
    <a
      href={path}
      className={classes.root}
      style={
        thisPage
          ? { boxShadow: 'rgba(0,0,0,0.6) 0px 0px 9px 2px', borderRadius: '10px' }
          : {}
      }
    >
      <div>
        <img className={classes.pic} src={pic} alt="icon"></img>
      </div>
      <div className={classes.des}>{des}</div>
      <div className={classes.des2}>{des2}</div>
    </a>
  );
};

export default withStyles(styles)(Title);
