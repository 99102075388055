import React from 'react';
import { withStyles } from '@material-ui/core';
import footericon from '../../assets/homepage/footer/footericon.png';
import Icon1 from '../../assets/homepage/footer/Wechat.jpg';
import Icon2 from '../../assets/homepage/footer/Contact.jpg';

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    backgroundColor: '#eff5fa',
    width: '100%'
  },
  item: {
    display: 'flex',
    //flexDirection: 'column',
    //flexFlow: 'row wrap',
    //width: '30vw',
    position: 'relative',
    //top: '-40px'，
    left: '10%'
  },
  items: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly'
  },
  link: {
    paddingBottom: '7%',
    fontFamily: 'Noto Sans SC, sans-serif',
    fontWeight: '700',
    fontSize:14
  }
};

const Info = props => {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <div
        style={{
          alignItems: 'center',
          position: 'relative',
          left: '100px',
          display: 'flex'
        }}
      >
        <img
          style={{
            width: '150px', //height: '150px',
            paddingTop: '7%'
          }}
          src={footericon}
          alt="footericon"
        ></img>
        <div className={classes.item}>
          <div className={classes.items}>
            <a className={classes.link} href="/">
              Home
            </a>
            <a href="/aboutUs" className={classes.link}>
              About Us
            </a>
            <a href="/talentPlan" className={classes.link}>
              Product
            </a>
            <a href="/schoolHiring" className={classes.link}>
            Activities
          </a>
            {/* <a href="/partners" className={classes.link}>
              合作伙伴
            </a>
            <a href="/joinus" className={classes.link}>
              Join Us
            </a> */}
          </div>
        </div>
      </div>

      <div
        style={{
          width: '30vw',
          display: 'flex',
          justifyContent: 'space-evenly'
        }}
      >
        <div
          style={{
            width: '14vw',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <img style={{ width: '100px' }} src={Icon1} alt="icon1"></img>
          <p
            style={{
              textAlign: 'center',
              paddingTop: '3%',
              fontFamily: 'Noto Sans SC, sans-serif',
              fontSize:12,
              lineHeight:1
            }}
          >
            Business
          </p>
          <p
            style={{
              textAlign: 'center',
              fontFamily: 'Noto Sans SC, sans-serif',
              fontSize:12
            }}
          >
            Development
          </p>
        </div>

        <div
          style={{
            width: '14vw',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <img style={{ width: '100px' }} src={Icon2} alt="icon2"></img>
          <p
            style={{
              textAlign: 'center',
              paddingTop: '3%',
              fontFamily: 'Noto Sans SC, sans-serif',
              fontSize:12,lineHeight:1
            }}
          >
            Learn more about
          </p>
          <p
            style={{
              textAlign: 'center',
              fontFamily: 'Noto Sans SC, sans-serif',
              fontSize:12
            }}
          >
            courses and products
          </p>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(Info);
