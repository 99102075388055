import React from 'react';
import { withStyles } from '@material-ui/core';
import SubNavItem from './SubNavItem';
import Icon1 from '../../../assets/product/product1.png';
import Icon2 from '../../../assets/product/product2.png';
import Icon3 from '../../../assets/product/product3.png';
import Icon4 from '../../../assets/product/product4.png';
import Icon5 from '../../../assets/product/product5.png';
import Icon6 from '../../../assets/product/product6.png';
import Icon7 from '../../../assets/product/product7.png';

const styles = {
  root: {
    width: '100%',
    //maxWidth: '1160px',
    //maxWidth: '1160px',
    //height: '14vh',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#e2f0f9'
  },

  adsData: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  adsLine: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  }
};

//   Talent Plan talentPlan,
// Advanced University advUniv,
// Job Search First Aid Kit firstAid,
// InternshipsPrograms fortunetIntern.
// 国际实习实训计划 globalIntern,
// 飞燕计划 flyForward,
// 归燕计划 flyHome

const AdsData = props => {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <div className={classes.adsData}>
        <div className={classes.adsLine}>
          <SubNavItem
            des={'Talent Plan'}
            des2={''}
            pic={Icon1}
            path={'/talentPlan'}
          />
          <SubNavItem
            des={'Advanced University'}
            des2={''}
            pic={Icon2}
            path={'/advUniv'}
          />
          <SubNavItem
            des={'Job Search First Aid Kit'}
            des2={''}
            pic={Icon3}
            path={'/firstAid'}
          />
          <SubNavItem
            des={'Internships'}
            des2={'Programs'}
            pic={Icon4}
            path={'./fortunetIntern'}
          />
        </div>
        {/* <div className={classes.adsLine}>
          <SubNavItem
            des={'国际实习'}
            des2={'实训计划'}
            pic={Icon5}
            path={'./globalIntern'}
          />
          <SubNavItem
            des={'飞燕计划'}
            des2={''}
            pic={Icon6}
            path={'./flyForward'}
          />
          <SubNavItem
            des={'归燕计划'}
            des2={''}
            pic={Icon7}
            path={'./flyHome'}
          />
        </div> */}
      </div>
    </div>
  );
};

export default withStyles(styles)(AdsData);
