import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import { API_URL } from '../global/API_URL';
import { connect } from 'react-redux';

const styles = {
  root: {
    position: 'relative',
    top: '54px',
    minHeight: '80vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  loginBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto'
  }
};

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.loginBox}>
          <Button
            style={{
              backgroundColor: '#0070ad',
              margin: '10px 0px',
              width: '200px',
              height: '40px',
              color: 'white',
              fontSize: '20px',
              fontWeight: '900'
            }}
            href={API_URL.LOGIN.LINKEDIN}
          >
            Linkedin
          </Button>
          <Button
            style={{
              backgroundColor: '#cf4235',
              margin: '10px 0px',
              width: '200px',
              height: '40px',
              color: 'white',
              fontSize: '20px',
              fontWeight: '900'
            }}
            href={API_URL.LOGIN.GOOGLE}
          >
            Google
          </Button>
          <Button
            style={{
              backgroundColor: 'black',
              margin: '10px 0px',
              width: '200px',
              height: '40px',
              color: 'white',
              fontSize: '20px',
              fontWeight: '900'
            }}
            href={API_URL.LOGIN.GITHUB}
          >
            Github
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(LoginPage);
