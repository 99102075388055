import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import BrowserNav from '../Navigation/BrowserNav.jsx';
import { useMediaQuery } from '../../customHook.js';
import MobileNav from './MobileNav.jsx';

const styles = {
  root: {
    width: '100%',
    //height: '79px',
    display: 'flex',
    position: 'relative'
  }
};

const Nav = props => {
  const { classes, navData } = props;
  let mobileView = useMediaQuery('(max-width: 740px)');
  return (
    <div className={classes.root}>
      {mobileView ? (
        <MobileNav navData={navData} />
      ) : (
        <BrowserNav navData={navData} />
      )}
    </div>
  );
};

export default withStyles(styles)(Nav);
