import React from 'react';
import { withStyles } from '@material-ui/core';

const styles = {
  root: {}
};

class DashBoard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <div>{localStorage.fullname}</div>;
  }
}

export default DashBoard;
