import React from 'react';
import { withStyles } from '@material-ui/core';

const styles = {
  root: {
    width: '100%',
    height: '12px',
    backgroundColor: '#f5f5f5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#5e5e5e',
    fontSize: '10px',
    letterSpacing: '0.9px',
    fontFamily: 'Noto Sans SC, sans-serif',
    fontWeight: '400'
  },
  '@media screen and (max-width: 720px)': {
    root: {
      height: 'auto',
      fontSize: '9px',
      heigth: '12px',
      letterSpacing: '0px'
    }
  }
};

const CopyRight = props => {
  const { classes } = props;

  return (
    <div>
      <p className={classes.root}>
        Copyright &copy; 2020 北京鼎邺科技有限公司. All rights reserved.
      </p>
      <a
        className={classes.root}
        href="https://beian.miit.gov.cn/"
        target="_blank"
      >
        京ICP备2021008696号
      </a>
      <p className={classes.root}>
        Icons made by Freepik from www.flaticon.com
      </p>
      <p className={classes.root}>
        Icons made by catkulo from www.flaticon.com
      </p>
      <p className={classes.root}>
        Photos from pixabay.com. Modifications to photos include cropping and
        toning.
      </p>
    </div>
  );
};

export default withStyles(styles)(CopyRight);
