import React from 'react';
import { withStyles } from '@material-ui/core';
import SubNavItem from './SubNavItem.jsx';
import Icon1 from '../../../assets/product/relationship.png';
import Icon2 from '../../../assets/product/clock.png';

const styles = {
  root: {
    width: '100%',
    //maxWidth: '1160px',
    //maxWidth: '1160px',
    //height: '14vh',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#e2f0f9'
  },

  adsData: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  adsLine: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-evenly'
  }
};

//   Talent Plan talentPlan,
// Advanced University advUniv,
// Job Search First Aid Kit firstAid,
// InternshipsPrograms fortunetIntern.
// 国际实习实训计划 globalIntern,
// 飞燕计划 flyForward,
// 归燕计划 flyHome

const AdsData = props => {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <div className={classes.adsData}>
        <div className={classes.adsLine}>
          <SubNavItem
            des={'Campus'}
            des2={'Recruitment'}
            pic={Icon1}
            path={'/schoolHiring'}
          />
          <SubNavItem
            des={'Talk with'}
            des2={'FLAG'}
            pic={Icon2}
            path={'/siliconValley'}
          />
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(AdsData);
