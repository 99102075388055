import React from 'react';
import { withStyles } from '@material-ui/core';
import Contact from '../../assets/homepage/footer/Contact.jpeg';
import WeChat from '../../assets/homepage/footer/Wechat.jpeg';

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#eff5fa'
  },
  columns: {
    width: '80%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  links1: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  links2: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  link: {
    width: '80%',
    height: '20%',
    // borderBottom: 'black solid 2px',
    fontSize: '16px',
    paddingTop: '3%',
    paddingBottom: '0%',
    fontFamily: 'Noto Sans SC, sans-serif',
    fontWeight: '400',

  },
  imgs: {
    display: 'flex',
    width: '80%',
    justifyContent: 'space-around',
    alignItems: 'center',
    paddingTop: '5%',
    paddingBottom: '5%'
  },
  pic: {
    width: '80%'
  },
  combine: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

const MobileInfo = props => {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <div className={classes.columns}>
        <div className={classes.links1}>
          <a href="/" className={classes.link}>
            Home
          </a>
          <a href="/schoolHiring" className={classes.link}>
            Activities
          </a>
        </div>
        <div className={classes.links2}>
        <a className={classes.link} href="/talentPlan">
            Product
          </a>
          <a href="/aboutUs" className={classes.link}>
            About Us
          </a>
        </div>
      </div>
      <div className={classes.imgs}>
        <div className={classes.combine}>
          <img
            className={classes.pic}
            src={WeChat}
            alt="Business Cooperation"
          ></img>
          <h5
            style={{
              paddingTop: 3,
              fontFamily: 'Noto Sans SC, sans-serif',
              fontWeight: '400'
            }}
          >
            Business
          </h5>
          <h5
            style={{
              paddingTop: 0,
              fontFamily: 'Noto Sans SC, sans-serif',
              fontWeight: '400'
            }}
          >
            Development
          </h5>
        </div>
        <div className={classes.combine}>
          <img className={classes.pic} src={Contact} alt="Consult"></img>
          <h5
            style={{
              paddingTop: 3,
              fontFamily: 'Noto Sans SC, sans-serif',
              fontWeight: '400'
            }}
          >
            Learn more about
          </h5>
          <h5
            style={{
              paddingTop: 0,
              fontFamily: 'Noto Sans SC, sans-serif',
              fontWeight: '400'
            }}
          >
            courses and products
          </h5>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(MobileInfo);
