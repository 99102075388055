import * as ActionTypes from '../constant/actionTypes';
import user from '../../flagdreamSDK/user';

//authLogin
export const login = code => dispatch => {
  return user
    .linkedinLogin(code)
    .then(() => {
      dispatch({
        type: ActionTypes.LOGIN
      });
      return true;
    })
    .catch(err => console.log(err));
};
