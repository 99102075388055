import React from 'react';
import { withStyles } from '@material-ui/core';
import CopyRight from './CopyRight.jsx';
import Contact from './Contact.jsx';
import Info from './Info.jsx';
import MobileInfo from './MobileInfo.jsx';
import { useMediaQuery } from '../../customHook.js';

const styles = {
  root: {
    position: 'relative',
    top: '50px',
    zIndex: '10'
  },
  // '@media screen and (max-width: 720px)': {
  //   root: {
  //     width: '720px'
  //   }
  // }
  '@media screen and (max-width: 720px)': {
    root: {
      top: '20px',
      paddingTop: '8%'
    }
  }
};

const Footer = props => {
  const { classes } = props;
  let mobileView = useMediaQuery('(max-width: 720px)');
  return (
    <footer className={classes.root}>
      {mobileView ? <MobileInfo /> : <Info />}
      <CopyRight />
    </footer>
  );
};

export default withStyles(styles)(Footer);
