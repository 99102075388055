import * as ActionTypes from '../constant/actionTypes';

export default function(state = false, action) {
  switch (action.type) {
    case ActionTypes.LOGIN:
      return true;
    case ActionTypes.LOGOUT:
      return false;
    default:
      return state;
  }
}
