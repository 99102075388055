import * as API_URL from '../global/API_URL';
import axios from 'axios';

//authRequest third party login

const authRequest = {
  async linkedinLogin(requestCode) {
    console.log('login');
    const url = API_URL.API_URL.LOGIN.LINKEDINAUTH;
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        provider: 'linkedin',
        code: requestCode,
        state: '987654321',
        redirect_url: 'http://localhost:3000/auth/linkedin/callback'
      })
    };

    return fetch(url, config)
      .then(_handleResponseToJson)
      .then(({ response }) => {
        let access_token = response.credential.access_token;
        let fullname = response.fullname;
        console.log('logged', access_token);
        localStorage.token = JSON.stringify(access_token);
        localStorage.fullname = JSON.stringify(fullname);
      });
  }
};

export default authRequest;

export const _handleResponseToJson = response => {
  // console.log('_handleResponseToJson');

  if (!response.ok) {
    if (response.status >= 500) {
      throw {
        status: response.status,
        statusText: response.statusText
      };
    }

    if (response.status >= 400) {
      return response.json().then(
        data => {
          data.status = response.status;
          data.statusText = response.statusText;
          return Promise.reject(data);
        },
        () =>
          Promise.reject({
            status: response.status,
            statusText: response.statusText
          })
      );
    }
  }

  if (response.status === 204) {
    return 'OK';
  }

  return response.json().then(
    json => ({ response: json, headers: response.headers }),
    () => 'OK'
  );
};
