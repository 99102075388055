import React from 'react';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { login } from '../global/actions/user';
import { Link, Redirect } from 'react-router-dom';
import Loading from '../market/assets/career/loading.gif';
import Auth from '../flagdreamSDK/user';

const styles = {
  root: {}
};

class DashBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  loginhandler() {
    const { dispatch } = this.props;
    const start = window.location.search.indexOf('code=');
    const end = window.location.search.indexOf('&state=');
    const code = window.location.search.substr(start + 5, end - start - 5);
    dispatch(login(code)).catch(err => console.log(err));
  }
  componentDidMount() {
    const start = window.location.search.indexOf('code=');
    const end = window.location.search.indexOf('&state=');
    const code = window.location.search.substr(start + 5, end - start - 5);
    this.loginhandler();
    this.setState({ loading: false });
  }
  render() {
    const { classes, loggedin } = this.props;
    const { loading } = this.state;

    if (loggedin) {
      return <Redirect to="/dashboard" />;
    }
    if (loading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={Loading} alt="loading" />{' '}
        </div>
      );
    } else return <Redirect to="/dashboard" />;
  }
}

function mapStoreStateToProps(state) {
  const loggedin = state.loggedin;
  return {
    loggedin
  };
}

export default connect(mapStoreStateToProps)(withStyles(styles)(DashBoard));
